<template>
  <div class="app-sumo-form-container">
    <div class="form p-4 mb-3">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img class="app-logo" src="@/assets/img/logos/ideaBuddy-color.svg">
        </div>
        <div class="px-3">
          <img src="@/assets/img/icons/yellow-heart.png" class="yellow-heart">
        </div>
        <div class="mt-2">
          <img class="app-logo" src="@/assets/img/logos/app-sumo-logo.png">
        </div>
      </div>

      <div class="mb-4">
        <p v-if="isLoggedIn">Hey Sumo-lings, please enter your name, email address
          below to get lifetime access to IdeaBuddy.</p>
        <p v-else>Hey Sumo-lings, please enter your name, email address, and password
          below to get lifetime access to IdeaBuddy.</p>

        <p>Thank you and enjoy using our app!</p>
      </div>

      <!-- Alert Error -->
      <transition name="fade">
        <div
          v-if="!isLoggedIn && showErrorAlertLogin"
          class="mb-3"
        >
          <el-alert
            :title="errorMessageLogin"
            type="error"
            show-icon
            :closable="false"
          />
        </div>
      </transition>
      <!-- /Alert Error -->

      <el-form
        ref="appSumoForm"
        class="app-sumo-form"
        :model="form"
        :rules="rules"
      >

        <!-- First Name -->
        <el-form-item
          class="input-inside-label"
          label="First name"
          prop="firstName"
          :class="{'focused': focus.firstName}"
        >
          <el-input
            v-model="form.firstName"
            :disabled="isLoggedIn || loading"
            placeholder=" "
            prefix-icon="el-icon-circle-check"
            suffix-icon="el-icon-info"
            @focus="toggleFocus('firstName')"
            @blur="toggleFocus('firstName')"
          />
        </el-form-item>
        <!-- /First Name -->

        <!-- Last Name -->
        <el-form-item
          class="input-inside-label"
          label="Last name"
          prop="lastName"
          :class="{'focused': focus.lastName}"
        >
          <el-input
            v-model="form.lastName"
            :disabled="isLoggedIn || loading"
            placeholder=" "
            suffix-icon="el-icon-info" prefix-icon="el-icon-circle-check"
            @focus="toggleFocus('lastName')"
            @blur="toggleFocus('lastName')"
          />
        </el-form-item>
        <!-- /Last Name -->

        <!-- Email -->
        <el-form-item
          class="input-inside-label"
          label="AppSumo email address"
          prop="email"
          :class="{'focused': focus.email}"
        >
          <el-input
            v-model="form.email"
            :disabled="isLoggedIn || loading"
            type="email"
            autocomplete="new-password"
            placeholder=" "
            suffix-icon="el-icon-info" prefix-icon="el-icon-circle-check"
            @focus="toggleFocus('email')"
            @blur="toggleFocus('email')"
          />
        </el-form-item>
        <!-- /Email -->

        <!-- Password -->
        <el-form-item
          v-if="!isLoggedIn"
          class="input-inside-label"
          label="Create a password"
          prop="password"
          :class="{'focused': focus.password}"
        >
          <el-input
            v-model="form.password"
            class="default password"
            autocomplete="new-password"
            placeholder=" "
            :disabled="loading"
            :show-password="true"
            @focus="toggleFocus('password')"
            @blur="toggleFocus('password')"
          />
        </el-form-item>
        <!-- /Password -->
      </el-form>
    </div>
    <!-- Google Terms & Conditions -->
    <p class="text-center terms-and-conditions google">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
    </p>
    <!-- /Google Terms & Conditions -->

    <ib-button
      size="lg"
      class="w-100"
      :loading="loading"
      @click="onSignUp"
    >
      LET ME IN
    </ib-button>

    <!-- /Terms & Conditions -->
    <p class="text-center terms-and-conditions">By continuing, you agree to IdeaBuddy <a
      href="https://ideabuddy.com/terms-of-service/" target="_blank"
    >Terms and Conditions</a> and
      <a href="https://ideabuddy.com/privacy-policy/" target="_blank">Privacy Policy</a>.
    </p>
    <!-- /Terms & Conditions -->
  </div>
</template>

<script>

import '@/options/googleRecaptcha'
import { mapActions, mapGetters } from 'vuex'
import AuthMixin from '@/mixins/auth'
import { intercomPaymentUserBoot } from '@/helpers/intercomHelpers'

export default {
  name: 'AppSumoForm',

  mixins: [AuthMixin],

  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        return callback(new Error(this.$t('validations.passwordMustBe')))
      }
      callback()
    }

    return {
      loading: false,
      errorMessageLogin: '',
      showErrorAlertLogin: false,
      focus: {
        firstName: false,
        lastName: false,
        email: false,
        dode: false,
        password: false
      },
      form: {
        firstName: null,
        lastName: null,
        email: null,
        code: null,
        password: null
      },
      rules: {
        firstName: [
          { required: true, message: 'Please enter First name', trigger: 'submit' }
        ],
        lastName: [
          { required: true, message: 'Please enter Last name', trigger: 'submit' }
        ],
        email: [
          { required: true, message: 'Please enter AppSumo email address.', trigger: 'submit' },
          { type: 'email', message: this.$t('validations.enterValidEmail'), trigger: 'submit' }
        ],
        password: [
          { required: true, message: 'Please enter password.', trigger: 'submit' },
          { validator: validatePassword, trigger: 'submit' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'getCurrentUser'])
  },

  mounted () {
    const code = this.$route.params.code
    if (typeof code !== 'string') this.$router.push({ name: '404' })
    this.form.code = code

    if (this.isLoggedIn) {
      this.setUserData()
    }
  },

  methods: {
    ...mapActions('auth', ['appSumoAuth']),

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input]) {
        this.focus[input] = true
      }
    },

    setUserData () {
      const user = this.getCurrentUser

      this.form.firstName = user.firstName
      this.form.lastName = user.lastName
      this.form.email = user.email

      this.toggleFocus('firstName')
      this.toggleFocus('lastName')
      this.toggleFocus('email')
    },

    onSignUp () {
      this.$refs.appSumoForm.validate((valid) => {
        if (!valid) {
          return false
        }

        this.loading = true

        this.$recaptcha('login').then(token => {
          this.appSumoAuth({ token, ...this.form })
            .then(response => {
              const { token, user } = response.data.payload
              this.setupCookie(token)

              this.$http.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
              this.$store.commit('user/setUser', { user: user })
              this.$store.commit('user/setSubscription', user)
              this.$gtm.trackEvent({
                event: 'SignupSuccess-appSumoSignup'
              })
              intercomPaymentUserBoot(user, this.$intercom)
              this.$router.push({ name: 'home' })
            }).catch(e => {
              const errorStatus = e.response.status
              if (errorStatus === 403) {
                this.errorMessageLogin = this.$t('validations.accountHasBeenCancelled')
                this.showErrorAlertLogin = true

                return
              }

              if (errorStatus === 400) {
                this.errorMessageLogin = 'Please check your entries and try again.'
                this.showErrorAlertLogin = true

                return
              }

              this.errorMessageLogin = this.$t('validations.sorrySomethingWentWrong')
              this.showErrorAlertLogin = true
            }).finally(() => {
              this.loading = false
            })
        }).catch(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-sumo-form >>> .el-input,
.app-sumo-form >>> input {
  max-width: none !important;
}

.app-sumo-form >>> .el-input__inner {
  border-radius: 4px !important;
}

.app-sumo-form-container {
  width: 90%;

  @include media-breakpoint-up($md) {
    width: 640px;
  }
}

.app-logo {
  width: 100px;

  @include media-breakpoint-up($md) {
    width: 180px;
  }
}

.app-sumo-form-container .form {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 15px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.app-sumo-form-container .yellow-heart {
  margin-top: 10px;
  width: 25px;

  @include media-breakpoint-up($md) {
    width: 45px;
  }
}

.app-sumo-form-container .terms-and-conditions {
  color: rgba(41, 47, 77, 0.5);
}

.app-sumo-form-container .terms-and-conditions.google {
  font-size: 15px;
}
</style>
