<template>
  <div
    class="app-sumo-container"
    :style="{background: 'url(' + require('../../assets/img/illustrations/mountain-saturized-op5.png') + '), #ffffff', }"
  >
    <app-sumo-header class="mb-5" />
    <app-sumo-form class="m-0 m-auto" />
  </div>
</template>

<script>
import AppSumoForm from '@/views/AppSumo/Components/AppSumoForm'
import AppSumoHeader from '@/views/AppSumo/Components/AppSumoHeader'

export default {
  name: 'Signup',

  components: {
    AppSumoForm,
    AppSumoHeader
  }
}
</script>

<style scoped>
.app-sumo-container {
  height: 100vh;
  background-size: cover !important;
  background-position: center bottom -200px !important;
  background-repeat: no-repeat !important;
}
</style>
